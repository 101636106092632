@import "../../index.scss";

.footer {
  padding: 3rem 8rem;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: white;

  @include Poppins;

  @include respond(phone) {
    flex-direction: column;
    padding: 3rem;
  }

  &-seperator {
    height: 100px;
    width: 2px;
    border: 1px solid $secondary-black;
    border-radius: 50%;
    margin: auto 0;

    @include respond(phone) {
      width: 100px;
      height: 2px;
      margin: 2rem auto;
    }
  }

  &-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      width: 100%;
      align-items: center;
    }

    &-image {
      width: 80%;
      height: auto;
    }
    &-name {
      margin-top: 1.5rem;
      font-size: 4.5rem;
      @include Playfair;
    }
  }

  &-middle {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond(phone) {
      width: 100%;
    }

    &-heading {
      font-size: 3rem;
    }
    &-links {
      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.6rem;
        color: white;
      }
      & img {
        width: 45px;
        height: auto;
        margin-right: 0.5rem;
      }
    }
  }

  &-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond(phone) {
      width: 100%;
    }

    &-heading {
      font-size: 3rem;
    }
    &-links {
      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.6rem;
        color: white;
      }

      & img {
        width: 45px;
        height: auto;
        margin-right: 0.5rem;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 2rem;
    font-size: 1.6rem;

    @include respond(phone) {
      flex-direction: column;
    }

    &-desc {
      & a {
        //text-decoration: none;
        color: white;
      }
    }
  }
}
