@import "../../index.scss";

.contactus {
  position: relative;
  text-align: center;
  padding: 3rem 8rem;

  background-position: center;
  background-size: cover;
  color: white;

  @include Poppins;

  @include respond(phone) {
    padding: 3rem;
  }

  &-back {
    text-decoration: none;
    padding: 1rem;
    font-size: 2rem;
    position: absolute;
    top: 3rem;
    left: 8rem;
    color: white;
    cursor: pointer;

    @include respond(phone) {
      left: 3rem;
    }
  }

  &-logo {
    @include respond(phone) {
      margin-top: 8rem;
    }
  }

  &-contents {
    margin: 6rem 0;
    display: flex;
    justify-content: space-between;

    @include respond(tablet) {
      flex-direction: column;
      align-items: center;
      margin: 8rem 0;
    }

    @include respond(phone) {
      flex-direction: column;
      margin: 3rem 0;
    }

    &-left {
      width: 45%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      @include respond(tablet) {
        padding-bottom: 5rem;
        width: 80%;
      }

      @include respond(phone) {
        width: 100%;
      }

      &-title {
        font-weight: 700;
        font-size: 6vw;

        @include respond(phone) {
          font-size: 9vw;
        }
      }

      &-line {
        width: 20vw;
        border-bottom: 2px solid white;
      }

      &-desc {
        font-size: 1.6rem;
        color: $secondary-black;
        margin-top: 2rem;
      }
    }
    &-right {
      width: 45%;

      @include respond(tablet) {
        width: 80%;
      }
      @include respond(phone) {
        width: 100%;
      }

      &-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 40px;
        overflow: hidden;

        @include respond(phone) {
          justify-content: space-between;
          margin-top: 2rem;
        }
      }

      &-email {
        margin-top: 2rem;
        font-size: 2rem;

        & a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }

  &-bottom {
    width: 30%;
    margin: 0 auto;
    margin-top: 3rem;

    @include respond(tablet) {
      width: 60%;
    }

    @include respond(phone) {
      width: 80%;
    }

    & img {
      width: 100%;
      height: auto;
    }
  }
}

.contactuscard {
  padding: 1rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: white;
  margin-top: 2rem;
  margin-right: 2rem;
  box-shadow: 8px 8px 8px rgb(142, 142, 142);

  @include respond(phone) {
    margin-right: 1rem;
    width: 45%;
    padding: 1.5rem 0rem;
  }

  & a {
    text-decoration: none;
  }

  &-image {
    & img {
      width: 4.5rem;
    }
  }

  &-title {
    font-size: 2rem;
    font-weight: 600;
    color: $black;
  }

  &-value {
    font-size: 1.6rem;
    font-weight: 400;
    color: $secondary-black;

    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
}
