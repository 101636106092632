@import "../../index.scss";

.experience {
  width: 100vw;
  // padding: 0rem 8rem;
  // background-size: cover;
  // background-position: center;
  background-color: #3662b4;
  // background: linear-gradient(to right, #07243b, #050d17);
  // background-color: #111111;
  border-radius: 0 5rem 5rem 0;
  // overflow-x: hidden !important;

  // height: 100vh;
  // display: flex;
  // justify-content: space-evenly;

  @include respond(tablet) {
    padding: 0 3rem;
  }

  @include respond(phone) {
    flex-direction: column;
    padding: 3rem 0;
  }

  // &-left {
  //   width: 50%;
  //   display: flex;
  //   flex-wrap: wrap;

  //   @include respond(phone) {
  //     width: 100%;
  //   }

  //   &-image {
  //     width: 50%;
  //     height: auto;
  //     overflow: hidden;
  //     //object-fit: scale-down;

  //     & img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;

  //       //object-fit: scale-down;
  //       object-position: center;
  //     }
  //   }

  //   &-text {
  //     width: 50%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     color: white;
  //     &-number {
  //       font-size: 9.6rem;
  //       font-weight: 700;
  //     }
  //     &-name {
  //       text-transform: uppercase;
  //       font-weight: 700;
  //       font-size: 2rem;
  //     }
  //   }
  // }

  &-top {
    // display: flex;
    // flex-direction: column;

    padding: 4rem;
    width: 60%;
    margin: 0 auto;

    @include respond(phone) {
      width: 100%;
      padding: 1rem;
    }

    &-title {
      color: white;
      font-size: 3.2rem;
      font-weight: 700;

      @include respond(phone) {
        font-size: 2.4rem;
      }
    }
    &-text {
      margin-top: 2rem;
      color: $light-white;
      font-size: 1.6rem;

      @include respond(phone) {
        font-size: 1.6rem;
      }
    }
  }

  &-cards {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}

.scard {
  display: flex;

  @include respond(phone) {
    flex-direction: column;
  }

  &-content {
    padding: 2rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      width: 100%;
      padding: 1rem;
    }

    &-title {
      font-size: 45px;
      // margin-bottom: 2rem;

      @include respond(phone) {
        font-size: 25px;
      }
    }
    &-sep {
      border: 2px solid $primary-blue;
      width: 80%;
      border-radius: 3rem;
    }
    &-desc {
      width: 70%;
      font-size: 16px;
      font-weight: 300;
      color: $secondary-black;
      margin: 4rem 1rem;

      @include respond(phone) {
        font-size: 16px;
        width: 90%;
        margin: 2rem 1rem;
      }
    }
    &-project {
      font-size: 26px;
      font-weight: 300;
      color: $primary-blue;

      @include respond(phone) {
        font-size: 18px;
      }
    }
  }
  &-image {
    width: 40%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    @include respond(phone) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }
  }
}
