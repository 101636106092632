@import "../../index.scss";

.services {
  padding: 3rem 0 0 0;
  position: relative;
  z-index: -10;
  overflow: hidden;
  //width: 100%;

  @include respond(phone) {
  }

  &-decorators {
    border-radius: 3rem;
    background-color: rgba(54, 98, 180, 0.17);
    position: absolute;

    //Other styles are mentioned at the end of the file.
  }

  &-bgtitle {
    text-transform: uppercase;
    font-size: 12rem;
    font-weight: 700;
    color: $tertiary-black;

    @include respond(phone) {
      font-size: 5rem;
    }
  }
  &-title {
    font-size: 6.4rem;
    font-weight: 500;

    @include respond(phone) {
      font-size: 4rem;
    }
  }

  &-first {
    padding: 0 8rem;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;

    @include respond(tablet) {
      padding: 2rem 3rem;
    }

    @include respond(phone) {
      padding: 0 3rem;
      flex-direction: column;
    }

    &-border {
      position: absolute;
      top: 10%;
      left: 0;
      height: 80%;
      width: 2rem;
      background-color: $primary-blue;
      border-radius: 0 6px 6px 0;

      @include respond(phone) {
        height: 90%;
        width: 1rem;
      }
    }
    &-left {
      display: flex;
      flex-direction: column;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      @include respond(tablet) {
        width: 45%;
      }

      @include respond(phone) {
        width: 100%;
      }

      &-image2 {
        width: 80%;
        align-self: end;
        transform: translateY(50px);
        @include respond(phone) {
          transform: translateY(25px);
        }
      }
      &-image1 {
        width: 50%;
        transform: translateY(-50px);

        @include respond(phone) {
          transform: translateY(-25px);
        }
      }
    }

    &-right {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 3rem;
      overflow: hidden;

      @include respond(tablet) {
        width: 45%;
        padding: 0;
      }

      @include respond(phone) {
        width: 100%;
        padding: 0;
      }

      &-title {
        font-weight: 700;
        font-size: 2vw;

        @include respond(phone) {
          font-size: 6vw;
        }
      }

      &-text {
        margin-top: 2rem;
        font-size: 1.6rem;
        color: $secondary-black;
      }

      &-tags {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  //Styles for 2nd service

  &-second {
    padding: 0 8rem;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    @include respond(tablet) {
      padding: 0 3rem;
      margin-bottom: 2rem;
    }

    @include respond(phone) {
      padding: 0 3.5rem;
      flex-direction: column-reverse;
      margin-bottom: 1rem;
    }

    &-border {
      position: absolute;
      top: 10%;
      right: 0;
      height: 80%;
      width: 2rem;
      background-color: $primary-blue;
      border-radius: 6px 0 0 6px;

      @include respond(phone) {
        height: 90%;
        width: 1rem;
      }
    }

    &-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      overflow: hidden;

      @include respond(tablet) {
        width: 45%;
        padding: 0;
      }

      @include respond(phone) {
        width: 100%;
        padding: 0;
      }

      &-title {
        font-weight: 700;
        font-size: 2vw;
        @include respond(phone) {
          font-size: 6vw;
        }
      }

      &-text {
        margin-top: 2rem;
        font-size: 1.6rem;
        color: $secondary-black;
      }

      &-tags {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;
      overflow: hidden;

      @include respond(tablet) {
        width: 45%;
      }

      @include respond(phone) {
        width: 100%;
      }

      &-image2 {
        width: 80%;
        transform: translateY(50px);

        @include respond(phone) {
          transform: translateY(25px);
        }
      }
      &-image1 {
        width: 50%;
        align-self: end;
        transform: translateY(-50px);

        @include respond(phone) {
          transform: translateY(-25px);
        }
      }
    }
  }

  &-third {
    width: 60%;
    margin: 3rem auto 5rem auto;

    @include respond(phone) {
      width: 90%;
    }

    &-title {
      font-weight: 700;
      font-size: 2vw;
      @include respond(phone) {
        font-size: 6vw;
      }
    }

    &-text {
      margin-top: 2rem;
      font-size: 1.6rem;
      color: $secondary-black;
    }
  }
}

.decorator1 {
  height: 416px;
  width: 416px;
  transform: rotate(34deg);
  top: -100px;
  right: -70px;
  z-index: 1000;
}

.decorator2 {
  height: 216px;
  width: 216px;
  transform: rotate(60deg);
  top: 35%;
  right: 45%;
  z-index: 1000;
}

.decorator3 {
  height: 416px;
  width: 416px;
  transform: rotate(34deg);
  top: 75%;
  right: 85%;
  z-index: 1000;
}

.decorator4 {
  height: 216px;
  width: 216px;
  transform: rotate(34deg);
  bottom: -10%;
  right: 15%;
  z-index: 1000;
  border-radius: 50%;
}
