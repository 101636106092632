@import "../../index.scss";

// .tag {
//   padding: 0.5rem 2rem;
//   border: 2px solid $primary-blue;
//   //background-color: black;
//   margin: 0.5rem;
//   border-radius: 0.5rem;

//   &-line {
//     border: none;
//     margin: 0.5rem 0;
//     border-bottom: 2px solid $primary-blue;
//     width: 4rem;
//   }

//   &-text {
//     text-align: left;
//     text-transform: uppercase;
//     color: $primary-blue;
//     font-size: 1.6rem;
//   }
// }

.tag {
  padding: 0.5rem 2rem;
  //border: 2px solid $primary-blue;
  background-color: $primary-blue;
  margin: 0.5rem;
  //border-radius: 0.5rem;

  &-line {
    border: none;
    margin: 0.5rem 0;
    border-bottom: 2px solid white;
    width: 4rem;
  }

  &-text {
    text-align: left;
    text-transform: uppercase;
    color: white;
    font-size: 1.6rem;

    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
