@import "../../index.scss";

.hero {
  position: relative;
  padding: 3rem 8rem;
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond(tablet) {
    padding: 3rem;
  }

  @include respond(phone) {
    padding: 3rem;
  }

  &-border {
    position: absolute;
    top: 10%;
    left: 0;
    width: 1.5rem;
    height: 80%;
    background-color: $primary-blue;
    border-radius: 0 6px 6px 0;
  }

  &-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.4;
    overflow: hidden;

    @include Roboto;

    &-subtext {
      font-size: 6vw;
      font-weight: 700;
      color: $secondary-black;

      @include respond(phone) {
        font-size: 10vw;
      }
    }

    &-text {
      text-align: center;
      color: #3e3e3e;
      font-size: 6vw;
      font-weight: 700;
      color: white;
      @include respond(phone) {
        font-size: 10vw;
      }
    }

    &-button {
      margin-top: 4rem;
      overflow: hidden;
    }
  }

  @include respond(phone) {
    font-size: 2.5rem;
  }

  &-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 80vh;
    object-fit: contain;
    width: 40vw;
    z-index: -50;
    border-radius: 0 0 0 30px;
  }
}

////////////////////////////////
/// Styles for the Slider.

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  display: flex;
  cursor: pointer;

  &-contents {
    width: 100%;
    position: relative;
    margin: 3rem 8rem;

    @include respond(phone) {
      margin: 3rem;
    }
    &-cross {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & svg {
        width: 6rem;
        height: auto;

        @include respond(tablet) {
          width: 5rem;
        }
        @include respond(phone) {
          width: 3rem;
        }
      }
    }
    &-header {
      text-transform: uppercase;
      font-size: 5vw;
      color: $primary-blue;
      @include Monoton;

      @include respond(phone) {
        margin-top: 3rem;
        font-size: 9vw;
      }
    }

    &-links {
      margin: 2rem;
      display: flex;
      flex-direction: column;

      &-link {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 5vw;
        color: white;

        @include respond(phone) {
          font-size: 9vw;
          margin: 1.5rem 0;
        }
      }

      & a {
        text-decoration: none;
      }

      &-link:hover {
        color: $primary-blue;
      }
    }
    &-contact {
      margin-top: 5rem;
      color: white;
      font-size: 2rem;

      & a {
        text-decoration: none;
        color: white;
      }

      & a:hover {
        color: $primary-blue;
      }
    }

    &-contact:hover a {
      color: $primary-blue;
    }
  }
}
