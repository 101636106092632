@import "../../index.scss";

.contact {
  margin: 5rem auto;
  padding-top: 2rem;
  width: 60%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2.5rem;
  display: flex;
  justify-content: space-evenly;

  @include respond(phone) {
    flex-direction: column-reverse;
    width: 80%;
  }

  @include respond(phone) {
    flex-direction: column-reverse;
    width: 90%;
  }

  &-left {
    width: 40%;
    @include respond(phone) {
      width: 100%;
    }

    & img {
      width: 70%;
    }
  }
  &-right {
    text-align: left;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond(phone) {
      width: 90%;
      margin: 2rem;
    }

    &-text {
      color: white;
      font-size: 2vw;
      font-weight: 700;
      margin-bottom: 3rem;

      @include respond(phone) {
        font-size: 6vw;
      }
    }

    &-desc {
      color: white;
      font-size: 2vw;
      margin-top: 2.5rem;

      @include respond(phone) {
        font-size: 5vw;
      }
    }

    &-details {
      display: flex;
      // justify-content: space-between;
      // flex-direction: column;
      flex-wrap: wrap;

      &-card {
        margin-right: 4rem;
        text-decoration: none;
        display: flex;
        color: white;
        font-size: 1.6rem;
        align-items: center;

        &-image {
          width: 4rem;
          height: auto;
        }
      }
    }
  }
}
