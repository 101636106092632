@import "../../index.scss";

.card {
  display: flex;
  flex-direction: column;

  //border-radius: 1rem;
  //box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.512);

  &-title {
    font-weight: 700;
    font-size: 2.4rem;
    color: $white;
  }

  &-text {
    padding: 2rem 3rem;
    color: $light-white;
    font-weight: 300;
    font-size: 2rem;

    @include respond(phone) {
      padding: 2rem 1rem;
      font-size: 1.6rem;
    }
  }

  &-image {
    //padding: 2rem;
    // width: 27.5rem;
    // height: auto;

    & img {
      width: auto;
      height: 15vw;
      //filter: drop-shadow(1px 2px 4px #616161);

      @include respond(phone) {
        height: 45vw;
      }
    }
  }

  &-seperator {
    display: none;

    @include respond(tablet) {
      border: 1px solid rgb(221, 221, 221, 0.7);
      text-align: center;
      display: block;
      margin: 3rem auto;
      width: 70%;
      border-radius: 50%;
    }

    @include respond(phone) {
      border: 1px solid rgb(221, 221, 221, 0.7);
      text-align: center;
      display: block;
      margin: 3rem auto;
      width: 70%;
      border-radius: 50%;
    }
  }
}
