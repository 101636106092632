@import "../../index.scss";

.scard {
  background-color: grey;
  min-height: 70vh;
  // margin: 1rem;
  width: 85%;
  border-radius: 10px;
  position: sticky;
  top: 50px;
  transition: transform 0.3s ease; /* Smooth transition */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

  @include respond(phone) {
    width: 100%;
    margin: 1rem 0;
  }
}
