@import "../../index.scss";

.button {
  z-index: 100;
  padding: 2rem 4rem;
  border: none;
  color: white;
  background-color: $primary-blue;
  border: 1px solid $primary-blue;
  font-size: 1.6rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  border: none;
  color: $primary-blue;
  background-color: white;
  border: 1px solid $primary-blue;
}
