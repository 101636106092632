@import "../../index.scss";

.heading {
  position: relative;
  overflow: hidden;
  //height: 22vh;

  &-backtext {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 9vw;
    line-height: 0.8;
    overflow: hidden;
    z-index: 50;

    @include respond(phone) {
      font-size: 12vw;
      margin-top: 1.5rem;
    }

    &-white {
      color: $watermark-white;
    }
    &-black {
      color: $watermark-black;
    }
  }

  &-maintext {
    margin-top: 3rem;
    font-size: 4vw;
    font-weight: 600;
    z-index: 1000;
    position: inherit;
    overflow: hidden;

    @include respond(phone) {
      font-size: 8vw;
      margin-top: 3rem;
    }

    &-white {
      color: $white;
    }
    &-black {
      color: $black;
    }
  }
}
