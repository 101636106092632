@import "../../index.scss";

.loader {
  display: grid;
  place-content: center;
  height: 100vh;
  background-color: black;

  &-logo {
    padding: 1rem;

    svg {
      width: 20rem;
      height: auto;

      @include respond(phone) {
        width: 15rem;
      }
    }
  }

  &-text {
    width: 100vw;
    margin-top: 4rem;
    font-size: 7rem;
    color: white;
    font-weight: 800;
    @include Bodoni;

    @include respond(phone) {
      font-size: 4rem;
    }

    &-letter {
      display: inline-block;
    }
  }
}
