@import "../../index.scss";

.header {
  display: flex;
  justify-content: space-between;

  &-logo {
    //width: 35%;

    @include respond(tablet) {
      width: 40%;
    }

    @include respond(phone) {
      width: 50%;
    }

    & svg {
      width: 100%;
    }
  }

  &-burger {
    cursor: pointer;
    width: 4%;

    @include respond(tablet) {
      width: 6%;
    }

    @include respond(phone) {
      width: 10%;
    }

    & svg {
      width: 100%;
    }
  }
}
