@import "../../index.scss";

.who {
  padding: 3rem 8rem;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $dark-bg;
  @include respond(phone) {
    padding: 3rem;
  }

  &-cards {
    margin: 5rem 0;
    display: flex;
    overflow: hidden;
    // justify-content: center;
    // flex-wrap: wrap;

    @include respond(tablet) {
      flex-direction: column;
    }
    @include respond(phone) {
      flex-direction: column;
    }
  }

  @include respond(phone) {
    flex-direction: column;
  }
}
